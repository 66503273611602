@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap);
body{
  font-family: 'El Messiri', sans-serif;
  margin: 0;
    color: rgb(94, 55, 8);
    background-image: 
  linear-gradient(rgba(255,255,255,.7), rgba(255, 255, 255, 0.7)),
  url('https://www.instagram.com/p/CBge8l3H-ce/');
  height: 30vh;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

h1{
  font-weight: 400;
}

video{
  width: 100%;
}

iframe{
  max-width: 100%;
}

.btn{
  margin: 5px;
}

.btn:hover{
  cursor: pointer;
}

.button{
  padding: 5px;
  background-color: whitesmoke;
  border-radius: 5px;
}


.button:hover{
text-decoration: none;
color: whitesmoke;
background-color: rgb(147, 208, 215);
transition: 500ms;
}

.banner-image{
  height: 400px;
  background-image: url(/static/media/class1.3abaf088.jpg);
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}



.classes-page{
  background-image: 
  /* linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, .4)), */
  url(/static/media/class2.8cb4fbce.jpg);
  background-size: cover;
  background-position: bottom;
}

.contact-page{
  background-image:
  linear-gradient(rgba(255, 255, 255,.8), rgba(255, 255, 255,.8)),
  url('https://images.unsplash.com/photo-1440342359743-84fcb8c21f21?ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60');
  background-size: cover;
  background-position: center;
}

.contact-buttons{
  margin: auto;
  padding: 5px;
}

.markdown img{
  max-width: 70%;
    max-height: 400px;
}
.price{
  height: 90px;
  width: 90px;
  border-radius: 100px;
  color: white;
  background-color: goldenrod;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price:hover{
  cursor: pointer;
  background-color: gold;
}

.price h4{
  margin-top: 9px;
  margin-left: 8px;
  margin-bottom: -8px;
}

.cards-purchase-text{
  margin: 20px;
}

.cd-wrapper{
  width: 200px;
  height: 170px;
  overflow: hidden;
  margin: 4px;
}



iframe{
  max-width: 100%;
}

.contact-social a{
margin: 7px;
}

.logo:hover{
  -webkit-filter: saturate(2);
          filter: saturate(2);
}



h6{
  margin-top: 2%;
  color: black;
}

.card{
  background-color: rgba(255, 255, 255, .3);
  margin: 5px 0 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.card:hover{
  background-color: rgba(255, 255, 255, .5);
  cursor: pointer;
  text-decoration: none;
}

.card h6:hover{
  text-decoration: none;
}

#whatsapp{
  color: green;
}

#phone{
  color: black;
}

#insta{
  color: rgb(109, 13, 13);
}
a{
  cursor: hand;
  text-decoration: none;
}

.auth-link{
  color: whitesmoke;
  margin-left: 10px;
  margin-top: 5px;
  
}

.auth-link:hover{
  cursor: pointer;
  color: rgb(61, 83, 19);
  text-decoration: none;
}

.auth-link a{
  margin-top: 52px;
}

.blog-item{
  background-color: rgb(239, 247, 239);
  margin: 20px;
  border-radius: 5px;
  padding: 10px;
}

.icon{
  margin-top: -3px;
}


.club-post{
padding: 10px;
background-color: rgb(179, 239, 250);
margin-bottom: 20px;
}

.club-post-text{
  padding: 10px;
}

.sign-in-error-message{
  padding: 4px;
  border: 1px solid red;
  color: red;
  text-align: center;
  background-color: rgb(245, 208, 214);
  border-radius: 3px;
  margin-top: 10px;
}

.content{
  padding: 3%;
  min-height: 100vh;
}

.day-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, .8);
  color: rgb(119, 54, 4);
  border-radius: 5px;
  padding: 20px;
}

.flags{
  display: flex;
  align-items: center;
  justify-content: center;
}
  
.flag{
  color: whitesmoke;
  margin: 0 4px;
}

.flag:hover{
cursor: pointer;
color: rgb(61, 83, 19);
}

.form{
  display: flex;
  flex-direction: column;
 width: 60%;
  margin: 25px auto;
  background-color: white;
  padding: 20px;
}

label{
  color: black;
}

.form input{
  margin-bottom: 20px;
  outline: none;
}

.form textarea{
  margin-bottom: 20px;
}

.form-content{
  margin-top: 50px;
}

.horarios{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 10px;
}

.home-project-list{
  margin-top: 25px;
  
}

.home-column{
  width: 80%;
  margin: 30px auto 0;
}

.home-column-post{
  padding: 5px;
  padding-bottom: 10px;
  background-color: rgb(230, 227, 227);
  margin: 10px auto;
}

.home-column-post img{
  margin-bottom: 5px;
}

.main-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}


.hero{
  background-image: 
  linear-gradient(rgba(255,255,255,.7), rgba(255, 255, 255, 0.7)),
  url('https://images.unsplash.com/photo-1440342359743-84fcb8c21f21?ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60');
  height: 30vh;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: bottom;
}



#hero-subtitle{
  font-weight: 100;
}

.hero-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: rgb(61, 83, 19);
background-image:
linear-gradient(rgba(255, 255, 255,.9), rgba(255, 255, 255,.2)),
url('https://media.giphy.com/media/xUA7aRkZwuLP7YVSEw/giphy.gif');
background-size: cover;
background-position: center;
/* background-attachment: fixed; */
}

.hero-text h1{
  font-size: 3rem;
  letter-spacing: 1rem;
}

.middle{
  padding: 5%;
  background-color: rgba(0,0,0,.3);

}

.middle-text{
  padding: 30px;
}

.not-found-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  background-image:
linear-gradient(rgba(255, 255, 255,.8), rgba(255, 255, 255,.8)),
url('https://images.unsplash.com/photo-1440342359743-84fcb8c21f21?ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60');
background-size: cover;
background-position: center;
}
.feature{
-webkit-filter: saturate(.5);
        filter: saturate(.5);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
justify-content: center;
color: rgb(103, 121, 14);
margin: 60px;
text-align: center;

}

.feature:hover{
  -webkit-filter: saturate(1.5);
          filter: saturate(1.5);

  cursor: pointer;
  color: rgb(49, 198, 218);
  text-decoration: none;
}

.feature img:hover{
  transform: rotate(90deg); 
  transition: 800ms;
}


.photo-container{
  display: flex;
  flex-direction: column;
  color: white;
}

.photo-container img{
max-width: 100%;
height: 300px;
}

.link:hover{
  text-decoration: none;
}

.privacy-link{
  margin-bottom: 25px;
  color: darkgreen;
}

.privacy-button{
  margin-left: 20px;
}

.taller-page{
  min-height: 100vh;
  background-image:
  linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255,.3)),
  url(/static/media/etxea.4780c330.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

}

.taller-card{
  background-color: rgba(252, 254, 251, 0.5);
  color: rgb(119, 54, 4);
  border-radius: 5px;
  margin: 90px 20px;
  padding: 20px;
}



.taller-info{
  color: rgb(119, 54, 4);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  padding:20px
}

.close-cross:hover{
cursor: pointer;
text-decoration: underline !important;
}

.privacy-link:hover{
  cursor: pointer;
}

.privacy-text{
  text-align: justify;
  margin-top: 25px;
}

.privacy-text a{
  color: black;
}

.recording-images{
  display: flex;
  margin-top: 10px;
  margin-left: 5px;
}
.recording-images a{
  margin-right: 5px;
}
  
.short-post{
  background-color: whitesmoke;
  padding: 15px;
}

.short-post img{
  border-radius: 0 0 9px 9px;
  margin-bottom: 5px;
}

  .small-button{
    border: .5px solid rgb(119, 54, 4);;
    border-radius: 15px;
    padding: 10px;
    color: rgb(173, 78, 6);;
    margin: 4% 0 0 0;
  }
  
  .xsmall-button{
    border-radius: 5px;
    padding: 5px;
    color: black!important;
    background-color: white;
    margin-bottom: 4%;
    outline: none;
    border: 1px solid gray;

  }
  
  .small-button:hover, .xsmall-button:hover{
    background-color:rgb(191, 174, 130);
    color: gray;
  text-decoration: none;
    transition: 500ms;
    cursor: pointer;
    border: none;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
  }

  .single-cd{
    display: flex;
    flex-direction: column;
  }

  .single-cd-links{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .single-cd-links a{
    color: black;
  }

  .audio-file{
    background-color: rgba(255, 255, 255, .9);
    padding: 9px;
    margin-bottom: 3px;
  }

  .text-box{
    background-color: rgba(0, 0, 0, 0.5);
    padding: 13px;
  }

  .text-box a{
    color: rgb(235, 210, 192);

  }

.text-box-light{
  background-color: rgba(255, 255, 255, .7);
  color: black;
  padding: 13px;
}



  .big-button{
    color: rgb(119, 54, 4);
    padding: 2%;
    background-color: rgb(119, 54, 4);
    border-radius: 10px;
    background-color: rgb(240, 226, 206);

  }
  
  .big-button:hover{
    background-color:rgb(163, 114, 76);
    color: rgb(119, 54, 4);
  text-decoration: none;
    transition: 500ms;
    cursor: pointer;
    border: none;
  }

  .nav-right{
    display: flex;
    align-items: center;
  }

  .nav-right a{
    margin: 7px;
    
  }

  .nav-link{
    padding: 0px;
  }

  #social{
margin-bottom: 20px;
text-align: right;  
}

.title-bar{
  
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title-bar h3{
  font-weight: 300;
  font-size: 3rem;
}
.sharing-buttons{
  text-align: right;
}

  
  #itunes{
    color: steelblue;
  }
  
  #itunes:hover{
    color: rgb(98, 179, 245);
  }
  
  #spotify{
    color: green;
  }
  
  #spotify:hover{
    color: rgb(5, 216, 5);
  }
  
/* LIGHTBOX ETC */
/* image grid styles */
.img-grid{
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img{
  min-width: 100%;
  min-height:100%;
  max-width: 10%;
  position: absolute;
  top: 0;
  right: 0;
  
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,8);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto 20px;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.backdrop h5{
  text-align: center;
  color: white;
}

.event{
  background-color: rgba(255, 255, 255, .7);
  color: black;
  margin-bottom: 2%;
  margin-top: 2%;
  padding: 5%;
  min-height: 300px;
}

.event img{
  margin-right: -60px;
  right: 0;
  width: 100%;
  
  
}




.recording a{
  color: rgb(235, 210, 192);
}

.project-card{
  background-color: rgba(255, 255, 255, .6);
  margin-bottom: 5%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(198, 233, 249);
}

.project-card img{
  width: 100%;
  border-radius: 5px;
}

.project-card-text{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.news-item h5{
  color: rgb(5, 40, 78);
  font-weight: 300;
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
} 

/*image overlays*/

.main-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
    position: relative;
    /* float: left; */
}
.main-box img{
	position:relative;
  	z-index:1;
}


.overlay img{
    width: 70%;
    margin: auto;
}
.overlay{
	position: absolute;
    z-index: 2;
    top: 0;
    opacity: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    background-color: #05050598;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
    text-align: center;
}
.main-box:hover .overlay{
	opacity:0.9;
}


.toolbar{
    position: fixed;
    background-color: rgb(138, 171, 140);
    /* color: grey; */
    width: 100%;
    min-height: 56px;
    top: 0;
    left: 0;
    z-index: 200;
  
    
}

.toolbar-icon:hover{
    -webkit-filter: saturate(2.5);
            filter: saturate(2.5);
}

.toolbar img{
    height: 56px;
}

.toolbar__navigation{
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo{
    margin-left: 1rem;
}
.toolbar__logo a{
text-decoration: none;
font-size: 1.5rem;
}

.spacer{
    flex: 1 1;
}
.toolbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 .5rem ;
}

.toolbar_navigation-items a {
    color: gray;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: rgb(192, 24, 24);
}

@media (max-width: 499px) {
    .desktop-only {
        display: none;
    }
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 30px;
    height: 2px;
    background: white;
  }

  .toggle-button:hover{
    transform: rotate(90deg);
    transition: 500ms;
  }


.side-drawer{
    height: 100%;
    overflow: auto;
  background-color: white;
    box-shadow: 10px 0px 10px rgba(5, 109, 128, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
}


.side-drawer.open{
    transform: translateX(0);
}

.drawer-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.drawer-logo:hover{
    -webkit-filter: saturate(1.5);
            filter: saturate(1.5);
}

.side-drawer li{
    margin-bottom: 4%;
}

.side-drawer ul{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    list-style: none;
}


.side-drawer a{
    color: rgb(94, 55, 8);
    text-decoration: none;

}

.dropdown-item{
    text-align: left;
}

.dropdown-item a{
    color: black;
}
.side-drawer a:hover,
.side-drawer a:active{
    color: rgb(191, 174, 130);
}

.close-cross{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px;
}

#cross:hover{
    color: rgb(191, 174, 130);

}

.header{
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 50px ;
margin: 3% 25px;   
padding: 25px; 
}

.social{
    color: rgb(235, 210, 192);
    display: flex;
    justify-content: space-around;
}

.social a{
    margin: 9px;
}

.i18n{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.3);
    z-index: 100;
    top: 0;
    left: 0;
}
